import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";

const SecondPage: React.FC = () => (
    <Layout>
        <SEO title={'Data Protection'} robots={'noindex,nofollow'}/>
        <div className="vita">
            <div className="wrapper">
                <div className="main">
                    <h1>Datenschutzerklärung</h1> <h2>Allgemeiner Hinweis und
                    Pflichtinformationen</h2><h3>Benennung der verantwortlichen Stelle</h3><p>Die verantwortliche Stelle
                    für die Datenverarbeitung auf dieser Website ist:</p><p><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJkAAAAwCAIAAACNLU/LAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC/ElEQVR4nO2cW5bDIAiGTU+X2qwqi+082ONYxF9Ukqjle2prggheEObM9n6/nbEEj7sVMNQwX67D9nq92IbjOC5WBbDvO/llKPUGYQvnpbfXgDZKFRtW1Xt53q1AC+ZFlrIv4/0tGHHf9+M4wvqIFwpuciessJyGoK8lN+1C7BPGn5rA+4x8KDY1ECTESDSUDKrqxcFBviRT+8YxH98ENbQ0DGKnZsrzklDrPL/zk7fMl0PQ4Abyinft7O5Ee2x6Crq++csKLJKuoZzA+GHQFxA4NYV1Sbaj/pkbCwxRbpUORJO0lW2K+1p1j92uzK2n+5jd+hW51Jdu0YvdIFztS+M8rE6yDubLdTBfrgNfv7wr2lS8/PwIsWs+63KQRLO6C0FaYIGMAdFflMOzVTIg6USsqF+ytUDQGvCPkcpi+hZWINcX251LknbxW6AJaMjmqoR12dyQsaEArDKN5yUxa5oCTYdRfLKhr9qvuKP0d+FYsC8ln1PhgJwQGsdWVfLkhUNW+6qzOfeWbulRomHcytZbagWywoUaxjxJHxeUfnQjjjPiF3WZZwv0Xvs6L0MBobOwlStcsF97ABtyD+qzmRVYNJREVGGPdX0WCdLjS05OuO7FAIuS3E96NAyP5YIjIhAYqo1P7IPP7RhJ5JZ7K36y2ATil1xfIAxJ+wJNOQ2FkVRxt2DjW1bDInFf+nWSYoBueNQNdUrNq3+6/Qi6hrL65TpYnWQdzJfrYL5ch/9cAc5PenD2UthUezvuifdAklOe/5yFz7pk77kushp7cQSXaHArBwLVAX5axoWBh8vbl80LB3ocyQo0Onmm9gUpWZyQkzQBgc2ARFK/wIkmnOjvCtSPlgaBwrXeXxsg2bgLCkdalONYPxjF461NYC4HrZKVjnVzrRXW2ymvS+H2K0dX4BmGnsV5hCcx6Fx58Fnql9fwcN9ng8vvMP1uVhdI6Hfk2RXWUyn/fx9hkc99ByC5JiAwR0/9sqF4meo/yzK1Osk6WD52HcyX6/AHNqtqd4tJtW8AAAAASUVORK5CYII="
                    alt="" width="153" height="48" /></p><p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die
                    Zwecke
                    und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
                    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3><p>Nur mit Ihrer ausdrücklichen
                    Einwilligung
                    sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
                    Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail.
                    Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                    unberührt.</p><h3>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h3><p>Als Betroffener
                    steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
                    zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher
                    Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres
                    Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren
                    Kontaktdaten bereit: <a
                        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                        target="_blank" rel="noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                </p><h3>Recht auf Datenübertragbarkeit</h3><p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage
                    Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
                    Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern
                    Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
                    nur, soweit es technisch machbar ist.</p><h3>Recht auf Auskunft, Berichtigung, Sperrung,
                    Löschung</h3><p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht
                    auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten,
                    deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
                    oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene
                    Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns
                    wenden.</p><h3>SSL- bzw. TLS-Verschlüsselung</h3><p>Aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
                    eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für
                    Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
                    Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
                </div>
                <div className="footer"> {(new Date()).getFullYear()} · tommy-seus.de</div>
            </div>
            <div className="loading"/>
        </div>
    </Layout>
)

export default SecondPage
